<template>
  <div :class="['vendor']">
    <div class="vendor_handle">
      <div
        v-if="vendorDetails"
        :class="['vendor_expand', expanded === true ? 'expanded' : '']"
        @touchend="expand($event, 'vendor')"
        @click="expand($event, 'vendor')"
      >
        >
      </div>
      <div class="vendor_title">
        {{ vendor.name }}
      </div>
      <div
        v-if="vendorDetails"
        :class="['vendor_checkbox', isLegitimateInterest ? (hasLegitimateInterest ? 'checked' : '') : (consented ? 'checked' : '')]"
        @touchend="toggleConsent($event)"
        @click="toggleConsent($event)"
      >
        {{ "cb_" + vendor.id }}
      </div>
    </div>
    <div
      v-if="vendorDetails"
      :class="['vendor_expanded', expanded === true ? 'expand' : '']"
    >
      <ExternalLink
        :url="nonIAB ? vendor.privacyPolicyUrl : getVendorPolicyUrl(vendor.urls)"
        :text="content.v_pp"
        class="block"
      />
      <ExternalLink
        v-if="!nonIAB"
        :url="getVendorLegIntClaimUrl(vendor.urls)"
        text="Legitimate interest claim"
        class="block"
      />
      <div
        v-if="vendor.cookieMaxAgeSeconds"
        class="details"
      >
        {{ content.v_cookiestorage }}
        {{ secondsToHms(vendor.cookieMaxAgeSeconds) }}
      </div>
      <div
        v-if="vendor.usesNonCookieAccess"
        class="details"
      >
        {{ content.v_cookieaccess }} {{ vendor.usesNonCookieAccess }}
      </div>
      <ExternalLink
        v-if="vendor.deviceStorageDisclosureUrl"
        :url="vendor.deviceStorageDisclosureUrl"
        :text="content.v_storagediscl"
        class="block"
      />
      <h3 v-if="exists(vendor.legIntPurposes)">
        {{ content.v_lipp }}
      </h3>
      <ul v-if="exists(vendor.legIntPurposes)">
        <li
          v-for="(purpose, i) in legIntPurposes"
          :key="i"
        >
          {{ purpose }}
        </li>
      </ul>
      <h3 v-if="exists(vendor.specialPurposes)">
        {{ content.v_spp }}
      </h3>
      <ul v-if="exists(vendor.specialPurposes)">
        <li
          v-for="(purpose, i) in specialPurposes"
          :key="i"
        >
          {{ purpose }}
        </li>
      </ul>
      <h3 v-if="exists(vendor.purposes)">
        {{ content.v_prp }}
      </h3>
      <ul v-if="exists(vendor.purposes)">
        <li
          v-for="(purpose, i) in purposes"
          :key="i"
        >
          {{ purpose }}
        </li>
      </ul>
      <h3 v-if="exists(vendor.specialFeatures)">
        {{ content.v_sf }}
      </h3>
      <ul v-if="exists(vendor.specialFeatures)">
        <li
          v-for="(feature, i) in specialFeatures"
          :key="i"
        >
          {{ feature }}
        </li>
      </ul>
      <h3 v-if="exists(vendor.features)">
        {{ content.v_f }}
      </h3>
      <ul v-if="exists(vendor.features)">
        <li
          v-for="(feature, i) in features"
          :key="i"
        >
          {{ feature }}
        </li>
      </ul>
      <h3 v-if="exists(vendor.dataDeclaration)">
        {{ content.v_data_declaration }}
      </h3>
      <ul v-if="exists(vendor.dataDeclaration)">
        <li
          v-for="(data, i) in dataDeclaration"
          :key="i"
        >
          {{ data }}
        </li>
      </ul>
      <h3 v-if="vendor.dataRetention && vendor.dataRetention.stdRetention">
        {{ content.v_data_retention }}
      </h3>
      <div v-if="vendor.dataRetention">
        <div
          v-if="vendor.dataRetention.stdRetention"
          class="details"
        >
          {{ vendor.dataRetention.stdRetention }} {{ content.v_days }}
        </div>
        <h4 v-if="Object.keys(vendor.dataRetention.specialPurposes).length > 0">
          {{ content.v_spp }}
        </h4>
        <ul v-if="Object.keys(vendor.dataRetention.specialPurposes).length > 0">
          <li
            v-for="(data, i) in dataRetentionSpecialPurposes"
            :key="i"
          >
            {{ data.name }}: {{ data.stdRetention }} {{ content.v_days }}
          </li>
        </ul>
        <h4 v-if="Object.keys(vendor.dataRetention.purposes).length > 0">
          {{ content.v_prp }}
        </h4>
        <ul v-if="Object.keys(vendor.dataRetention.purposes).length > 0">
          <li
            v-for="(data, i) in dataRetentionPurposes"
            :key="i"
          >
            {{ data.name }}: {{ data.stdRetention }} {{ content.v_days }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalLink from '@components/ExternalLink.vue'

export default {
  name: 'Vendor',
  components: {
    ExternalLink,
  },
  props: {
    vendor: Object,
    vendorDetails: Boolean,
    isLegitimateInterest: {
      type: Boolean,
      default: false,
    },
    defaultConsent: {
      type: Boolean,
      default: false,
      required: false,
    },
    nonIAB: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      consented: false,
      expanded: false,
      hasLegitimateInterest: false,
      legIntPurposes: [],
      specialPurposes: [],
      purposes: [],
      specialFeatures: [],
      features: [],
      dataDeclaration: [],
      dataRetentionPurposes: [],
      dataRetentionSpecialPurposes: [],
      content: {},
    }
  },
  watch: {
    'vendor.consent': function (newVal, oldVal) {
      // watch for changes in consent; request update if changes
      if (newVal !== oldVal) {
        if (this.nonIAB) {
          // non IAB
          this.consented = this.$compliance.nonIAB.getVendorConsent(
            this.vendor.id
          )
        } else {
          // IAB
          this.consented = this.$compliance.TCF20.getVendorConsent(
            this.vendor.id
          )
        }
      }
    },
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    // set consented value to what is defined in compliance module
    if (this.nonIAB) {
      this.consented = this.$compliance.nonIAB.getVendorConsent(this.vendor.id)
    } else {
      if (this.isLegitimateInterest)
        this.hasLegitimateInterest = this.$compliance.TCF20.getVendorLegitimateInterest(
          this.vendor.id
        )
      else
        this.consented = this.$compliance.TCF20.getVendorConsent(this.vendor.id)
    }
  },
  methods: {
    getVendorPolicyUrl(urls) {
      let localisedUrl = urls[0].privacy // fallback; most have only one entry anyway
      // choose localised entry if available
      for (const url of urls) {
        if (url.langId === this.$compliance.lang) {
          localisedUrl = url.privacy
          break;
        }
      }
      return localisedUrl
    },
    getVendorLegIntClaimUrl(urls) {
      let localisedUrl = urls[0].legIntClaim // fallback; most have only one entry anyway
      // choose localised entry if available
      for (const url of urls) {
        if (url.langId === this.$compliance.lang) {
          localisedUrl = url.legIntClaim
          break;
        }
      }
      return localisedUrl
    },
    secondsToHms(t) {
      if (t) {
        t = Number(t)
        // negative values return without transformation
        if (t <= 0) return t
        var d = Math.floor(t / (24 * 3600))
        var h = Math.floor((t % (24 * 3600)) / 3600)
        var m = Math.floor((t % (24 * 3600)) / 3600 / 60)
        var s = Math.floor(t % 60)

        var dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : ''
        var hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : ''
        var mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : ''
        var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
        return dDisplay + hDisplay + mDisplay + sDisplay
      } else {
        return 'undefined'
      }
    },
    expand(event, type) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (type === 'legal') this.expandedLegal = !this.expandedLegal
      if (type === 'vendors') this.expandedVendors = !this.expandedVendors
      if (type === 'vendor') {
        this.expanded = !this.expanded

        // legitimate purposes
        for (const item in this.vendor.legIntPurposes) {
          this.legIntPurposes.push(
            this.$compliance.GVL.getPurpose(
              'purpose',
              this.vendor.legIntPurposes[item]
            ).name
          )
        }
        // special purposes
        for (const item in this.vendor.specialPurposes) {
          this.specialPurposes.push(
            this.$compliance.GVL.getPurpose(
              'specialPurpose',
              this.vendor.specialPurposes[item]
            ).name
          )
        }
        // purposes
        for (const item in this.vendor.purposes) {
          this.purposes.push(
            this.$compliance.GVL.getPurpose(
              'purpose',
              this.vendor.purposes[item]
            ).name
          )
        }
        // features
        for (const item in this.vendor.features) {
          this.features.push(
            this.$compliance.GVL.getFeature(this.vendor.features[item]).name
          )
        }
        // special features
        for (const item in this.vendor.specialFeatures) {
          this.specialFeatures.push(
            this.$compliance.GVL.getFeature(this.vendor.specialFeatures[item])
              .name
          )
        }
        // data declarations
        for (const item in this.vendor.dataDeclaration) {
          this.dataDeclaration.push(
              this.$compliance.GVL.getDataCategory(this.vendor.dataDeclaration[item]).name
          )
        }
        // data retention purposes & special purposes
        if (this.vendor.dataRetention) {
          for (const item in this.vendor.dataRetention.purposes) {
            this.dataRetentionPurposes.push(
                {
                  "name":
                  this.$compliance.GVL.getPurpose(
                      'purpose', item
                  ).name,
                  "stdRetention": this.vendor.dataRetention.purposes[item]
                }
            )
          }
          for (const item in this.vendor.dataRetention.specialPurposes) {
            this.dataRetentionSpecialPurposes.push(
                {
                  "name":
                  this.$compliance.GVL.getPurpose(
                      'specialPurpose', item
                  ).name,
                  "stdRetention": this.vendor.dataRetention.specialPurposes[item]
                }
            )
          }
        }
      }
    },
    toggleConsent(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (this.isLegitimateInterest) {
        this.hasLegitimateInterest = !this.hasLegitimateInterest
        if (this.hasLegitimateInterest) {
          this.$compliance.TCF20.setVendorLegitimateInterest(this.vendor.id)
        } else {
          this.$compliance.TCF20.unsetVendorLegitimateInterest(this.vendor.id)
        }
      } else {
        this.consented = !this.consented
        this.vendor.consent = this.consented // update watched value aswell

        if (this.consented) {
          if (this.nonIAB)
            this.$compliance.nonIAB.setVendorConsent(this.vendor.id)
          else this.$compliance.TCF20.setVendorConsent(this.vendor.id)
        } else {
          if (this.nonIAB)
            this.$compliance.nonIAB.unsetVendorConsent(this.vendor.id)
          else this.$compliance.TCF20.unsetVendorConsent(this.vendor.id)
        }
        this.$emit('consented', this.consented)
      }
    },
    exists(prop) {
      if (prop !== undefined && prop !== null && prop.length > 0) return true
      return false
    },
  },
}
</script>

<style lang="scss">
.vendor {
  background: #fff;
  margin: 0 0 8px 0;
  color: #4c5d6a;
  position: relative;

  .vendor_handle {
    display: flex;
    align-items: center;

    .vendor_expand {
      position: relative;
      overflow: hidden;
      background-image: url('../../assets/images/btn_expand.png');
      background-size: contain;
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 30px;
      height: 30px;
      justify-self: start;
      transform: rotate(-90deg);
      transition: transform 0.5s;
      flex-basis: 30px;
      &.expanded {
        transform: rotate(0);
      }
      @media screen and (min-width: 768px) {
        width: 55px;
        height: 55px;
        flex-basis: 55px;
      }
    }
    .vendor_title {
      font-size: 1.2em;
      line-height: 1.2em;
      align-self: center;
      flex-basis: calc(100% - 70px);
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
    .vendor_checkbox {
      background-image: url('../../assets/images/btn_checkbox_empty.png');
      background-size: contain;
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 40px;
      height: 30px;
      flex-basis: 40px;
      &.checked {
        background-image: url('../../assets/images/btn_checkbox.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
      @media screen and (min-width: 768px) {
        width: 50px;
        height: 40px;
        flex-basis: 50px;
      }
    }
    .vendor_object {
      color: #688194;
      text-decoration: underline;
      width: auto;
      height: 30px;
      flex-basis: 40px;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        flex-basis: 120px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
  }

  .vendor_expanded {
    font-size: 1em;
    display: none;
    margin: 5px 0 5px 0;
    padding: 0 20px;
    height: 0;
    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.4rem;
    }

    &.expand {
      height: auto;
      display: block;
    }
    .policy_link {
      color: #688194;
      text-decoration: underline;
    }
    .details {
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
      }
    }
    h3 {
      font-size: 1.2em;
      color: #626d77;
      margin: 10px 0 5px 0;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
    h4 {
      color: #626d77;
      margin: 10px 0 5px 0;
    }
    ul {
      margin: 0;
      padding: 0 0 0 20px;
    }
  }
}
.ar .vendor .vendor_handle .vendor_expand {
  transform: rotate(90deg);
  &.expanded {
    transform: rotate(0);
  }
}
</style>
